import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/post.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const YouTube = makeShortcode("YouTube");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`GraphQL middleware can be an easy way to abstract schema modifications from your resolvers and allow data to flow around them in the format they should be.`}</p>
    <p>{`In this example we'll quickly explore how to setup and configure GraphQL middleware with Apollo Server.`}</p>
    <YouTube youTubeId="5ydCPvrWRmg" mdxType="YouTube" />
    <p>{`It's as easy as importing `}<inlineCode parentName="p">{`applyMiddleware`}</inlineCode>{` from the `}<inlineCode parentName="p">{`graphql-middleware`}</inlineCode>{` package and applying it to your schema.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const { ApolloServer, gql, makeExecutableSchema } = require("apollo-server");
const { applyMiddleware } = require("graphql-middleware");

const uppercaseCategory = async (resolve, parent, args, context, info) => {
  const result = await resolve(parent, args, context, info);

  return result.toUpperCase();
};

const postsMiddleware = async (resolve, parent, args, context, info) => {
  const result = await resolve(parent, args, context, info);

  const formattedPosts = result.reduce(
    (formatted, post) => [
      ...formatted,
      {
        ...post,
        title: \`\${post.category}: \${post.title}\`,
      },
    ],
    []
  );

  return formattedPosts;
};

const postMiddleware = {
  Post: {
    category: uppercaseCategory,
  },
  Query: {
    posts: postsMiddleware,
  },
};

const middleware = [postMiddleware];

const schemaWithMiddleware = applyMiddleware(schema, ...middleware);

const server = new ApolloServer({ schema: schemaWithMiddleware });
`}</code></pre>
    <h2>{`Links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/notrab/video-graphql-middleware"
        }}>{`Code for tutorial`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/prisma-labs/graphql-middleware"
        }}><inlineCode parentName="a">{`graphql-middleware`}</inlineCode></a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      